import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';

import {DataGrid} from '@mui/x-data-grid';
import {Box, IconButton, InputAdornment, Menu, MenuItem, TextField} from '@mui/material';
import {Button, ButtonGroup} from '@mui/joy';
import {ClearOutlined, Search} from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import projectIcon from './icons/icons8-project-96.png';

import {v4 as uuidv4} from "uuid";

import './cloudStorage.css';
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";


const SharedContent = () => {
    const [csrfToken, setCsrfToken] = useState(null);

    const location = useLocation();
    const {
        page_mode,
        user_name,
        user_id,
        storage_key,
        storage_space,
        storageData,
        current_path,
        current_fold_name,
        sessionId_back,
        originalOpener_back,
        callback_url,
        version,
    } = location.state;
    const pageMode = page_mode;
    const username = user_name;
    const storageSpace = storage_space;
    const userId = user_id;
    const versionType = version;
    const S3RootPath = versionType === "STAGE" ? "space_stage_version" : "test_space";

    const [selectedRows, setSelectedRows] = useState([]);

    const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [selectedButton, setSelectedButton] = useState('share with me');  // track the buttons in the side nav
    const [searchTerm, setSearchTerm] = useState('');  // track the search item


    const [rows, setRows] = useState([  // track the rows in the file list
    ]);

    useEffect(() => {
        const transformedData = storageData
            .filter(item => item.ParentNode === current_fold_name)
            .map((item, index) => ({
                id: item.ProjectId,
                type: 'Project',
                ownerName: item.OwnerFirst + " " + item.OwnerLast,
                projectName: item.FileName,
                project_narration: item.ProjectNarration,
                encrypted_id: item.id,
                creationDate: new Date(item.CreationDateTime),
                owner_storage_id: item.StorageID
            }));

        setRows(transformedData);
    }, [storageData, storage_key]);


    useEffect(() => {
        if (searchTerm === '') {
            handleShareRoot();
        }
    }, [searchTerm]);


    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.4,
            renderCell: (params) => {
                return <img src={projectIcon} alt="Project" style={{width: '30px', height: '30px'}}/>
            },
        },
        {field: 'ownerName', headerName: 'Owner', flex: 0.5},
        {field: 'projectName', headerName: 'Project Name', flex: 1},
        {
            field: 'creationDate',
            headerName: 'Date of Creation',
            type: 'date',
            flex: 0.7,
            renderCell: (params) => {
                const utcDate = new Date(params.value + 'Z');

                return utcDate.toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false // Use 24-hour format
                });
            }
        },
        {
            field: 'action',
            headerName: '',
            flex: 0.2,
            renderCell: (params) => (
                <>
                    <IconButton
                        aria-label="more"
                        aria-controls="action-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent event from bubbling up to the DataGrid's onCellClick
                            handleActionMenuClick(e, params.row.id);
                        }}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id="action-menu"
                        anchorEl={actionMenuAnchor}
                        keepMounted
                        open={Boolean(actionMenuAnchor) && currentRowId === params.row.id}
                        onClose={handleActionMenuClose}
                    >
                        <MenuItem onClick={(e) => {
                            e.stopPropagation();
                            handleActionMenuClose();
                            handleProjectLoad([currentRowId]);
                        }}>Load Project</MenuItem>
                    </Menu>
                </>
            ),
        },
    ];

    const handleActionMenuClick = (event, rowId) => {
        setCurrentRowId(rowId);
        setActionMenuAnchor(event.currentTarget);
        event.stopPropagation(); // This will prevent the event from reaching the row
    };


    const handleActionMenuClose = (event) => {
        setActionMenuAnchor(null);
        event && event.stopPropagation();
    };


    const navigate = useNavigate();


    const handleSearchClear = () => {
        setSearchTerm('');
        handleShareRoot();
    };

    const handleSearchClick = () => {
        const filteredProjects = rows.filter(project => project.projectName.includes(searchTerm));
        setRows(filteredProjects);
    };

    const handleRowSelection = (row) => {
        // If the row is already selected, remove it from the selection
        if (selectedRows.includes(row.id)) {
            setSelectedRows(selectedRows.filter(id => id !== row.id));
        } else {
            setSelectedRows([row.id]);
        }
    };

    const handleProjectLoad = (selectedRowIds) => {
        console.log(selectedRowIds);
        let DataList = [];
        selectedRowIds.forEach(rowId => {
            DataList.push(rowId);
        })
        const dataToSend = {
            dataType: 'collaboration',
            result: DataList,
            sessionId: sessionId_back,
            callback_url: callback_url,
            storage_key: rows.find(project => project.id === selectedRowIds[0]).owner_storage_id
        };

        console.log(dataToSend);

        const originalOpener = window.myGlobalOpener;
        if (originalOpener && !originalOpener.closed) {
            originalOpener.postMessage(dataToSend, callback_url);
            window.close();
        } else {
            console.error('The opening window is no longer available.');
        }
    };


    const handleProjectRoot = () => {
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        const encrypted_id = 0;
        axios.post(
            '/api/project/${storage_key}/',
            {
                parameters: {
                    folder_name: 'project',
                    encrypted_id: storage_key,
                    version: versionType
                },
                request_name: 'enter_project', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: storage_key + '/' + 'projects' + '/',
                    current_fold_name: 'projects',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                    version: versionType
                };

                // Navigate to project page with the unique URL
                navigate(`/project-manager/${storage_key}`, {state: userData});// Navigate to CloudStorage with the unique URL
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }

    const handleStorageRoot = () => {

        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        axios.post(
            '/api/storage/${encrypted_id}/',
            {
                parameters: {
                    folder_name: 'useruploads',
                    encrypted_id: storage_key,
                    current_path: `${S3RootPath}/` + storage_key + '/',
                    version: versionType
                },
                request_name: 'enter_folder', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: `${S3RootPath}/` + storage_key + '/' + 'useruploads' + '/',
                    current_fold_name: 'useruploads',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                    version: versionType
                };

                // Navigate to CloudStorage with the unique URL
                navigate(`/storage/${storage_key}`, {state: userData});
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }


    const handleShareRoot = () => {
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        const encrypted_id = 0;
        axios.post(
            '/api/share/${storage_key}/',
            {
                parameters: {
                    folder_name: 'project',
                    encrypted_id: storage_key,
                    version: versionType
                },
                request_name: 'enter_share_with_me', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: storage_key + '/' + 'projects' + '/',
                    current_fold_name: 'projects',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                    version: versionType
                };

                // Navigate to project page with the unique URL
                navigate(`/share-with-me/${storage_key}`, {state: userData});// Navigate to CloudStorage with the unique URL
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }

    const handleJobRoot = () => {

        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        const userData = {
            page_mode: pageMode,
            user_name: user_name,
            user_id: userId,
            storage_key: storage_key,
            storage_space: storageSpace,
            current_path: `${S3RootPath}/` + storage_key + '/' + 'useruploads' + '/',
            current_fold_name: 'useruploads',
            sessionId_back: sessionId_back,
            callback_url: callback_url,
            version: versionType
        };
        navigate(`/job-manager/${storage_key}`, {state: userData});
    }


    const handleProjectSelectionConfirm = (e) => {

    };

    const handleProjectSelectionCancel = (e) => {

    };


    return (
        <div className="page-container">
            <h1>Welcome, {username}!</h1>
            <div className="flex-container">
                <div className="left-nav">
                    {pageMode !== "project_selection" && (
                        <TextField
                            fullWidth
                            placeholder="Search the Project"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleSearchClick}>
                                            <Search/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchTerm && (
                                            <IconButton onClick={handleSearchClear}>
                                                <ClearOutlined style={{fontSize: '20px'}}/>
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    <Box sx={{mt: pageMode === "project_selection" ? 0 : 2,}}>
                        <ButtonGroup
                            fullWidth
                            variant="contained"
                            color="primary"
                            orientation="vertical"
                            sx={{
                                fontSize: "larger", // or a specific size like "20px"
                                '& .MuiButton-root': {
                                    padding: "12px 24px",  // Adjust padding as needed
                                }
                            }}
                        >
                            {(pageMode !== "project_selection" && pageMode !== "project_management") && (
                                <Button
                                    onClick={() => {
                                        setSelectedButton('my storage')
                                        handleStorageRoot();
                                    }}
                                    variant={selectedButton === 'my storage' ? 'solid' : 'outlined'}
                                >
                                    My Storage
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    setSelectedButton('my project');
                                    handleProjectRoot();

                                }}
                                variant={selectedButton === 'my project' ? 'solid' : 'outlined'}
                            >
                                My Projects
                            </Button>
                            <Button
                                onClick={() => {
                                    setSelectedButton('share with me');
                                    handleShareRoot();
                                }}
                                variant={selectedButton === 'share with me' ? 'solid' : 'outlined'}
                            >
                                Shared with Me
                            </Button>
                        </ButtonGroup>
                    </Box>
                </div>
                <div className="main-content">
                    {pageMode !== "project_selection" && (
                        <Box display="flex" flexDirection="row" justifyContent="flex-start" mb={2} gap={2}>
                            <Button variant="outlined"
                                    disabled={selectedRows.length === 0}
                                    onClick={() => handleProjectLoad(selectedRows)}
                                    startDecorator={<AssignmentTurnedInOutlinedIcon/>}
                            >
                                Load Project
                            </Button>
                        </Box>
                    )}
                    <div style={{height: 108 + (10 * 52.345) + 'px', width: '100%'}}>
                        <DataGrid
                            sx={{  // remove the outline on focus in datagrid
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                },
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    display: "none"
                                }
                            }}
                            checkboxSelection
                            rowSelectionModel={selectedRows}
                            onCellClick={handleRowSelection}
                            rows={rows}
                            columns={pageMode === "project_selection" ? columns.slice(0, -1) : columns}
                            sortModel={[
                                {
                                    field: 'creationDate',
                                    sort: 'desc', // or 'asc' for ascending order
                                },
                            ]}
                            autoHeight={false}
                        />
                    </div>
                    <br></br>
                    {
                        (pageMode === "development_mode" || pageMode === "project_selection") && (
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" mb={2} gap={2}>
                                <Button variant="outlined" onClick={handleProjectSelectionConfirm}
                                        className="custom-button">
                                    Confirm
                                </Button>
                                <Button variant="outlined" onClick={handleProjectSelectionCancel} className="custom-button">
                                    Cancel
                                </Button>
                            </Box>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default SharedContent;
