import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';

import {Box, IconButton, InputAdornment, Menu, MenuItem, TextField,} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';

import {ClearOutlined, Search} from '@mui/icons-material';
import {Button, ButtonGroup, Checkbox, Modal, ModalClose, ModalDialog, Snackbar, Typography} from '@mui/joy';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import projectIcon from './icons/icons8-project-96.png';
import demoProjectIcon from './icons/icons8-demo-project-96.png';

import {v4 as uuidv4} from "uuid";
import './cloudStorage.css';
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const ProjectManager = () => {
    const [csrfToken, setCsrfToken] = useState(null);

    const location = useLocation();
    const {
        page_mode,
        user_name,
        user_id,
        storage_key,
        storage_space,
        storageData,
        current_path,
        current_fold_name,
        sessionId_back,
        originalOpener_back,
        callback_url,
        version
    } = location.state;
    const pageMode = page_mode;
    const username = user_name;
    const storageSpace = storage_space;
    const userId = user_id;
    const versionType = version;
    const S3RootPath = versionType === "STAGE" ? "space_stage_version" : "test_space";

    const [projectDialogOpen, setProjectDialogOpen] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [projectCollaborator, setProjectCollaborator] = useState("");

    const [projectId, setProjectId] = useState("");
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [currentProjectDetails, setCurrentProjectDetails] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isRunningJobDialogOpen, setIsRunningJobDialogOpen] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [actionTaken, setActionTaken] = useState(false);  // track whether an action (load project) has been initiated

    const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [selectedButton, setSelectedButton] = useState('my project');  // track the buttons in the side nav
    const [searchTerm, setSearchTerm] = useState('');  // track the search item

    const [deleteConfirmationChecked, setDeleteConfirmationChecked] = useState(false);

    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isCreatingProject, setIsCreatingProject] = useState(false);
    const [isDeletingProject, setIsDeletingProject] = useState(false);
    const [isUpdatingProject, setIsUpdatingProject] = useState(false);

    const [isPageLoading, setIsPageLoading] = useState(false);

    const [rows, setRows] = useState([]);


    useEffect(() => {
        const processAndSetRows = async () => {
            let additionalRows = [];
            if (storage_key !== '5MDSLse9kgHEewgu') {
                additionalRows = await fetchDemoProjectData('5MDSLse9kgHEewgu');
            }

            const userProjectData = storageData
                .filter(item => item.ParentNode === current_fold_name && item.Status !== 'Temporary')
                .map((item, index) => ({
                    id: item.ProjectId,
                    type: 'Project',
                    encrypted_id: item.id,
                    project_id: item.ProjectId,
                    projectName: item.FileName,
                    project_narration: item.ProjectNarration,
                    collaborators: item.collaboratorList,
                    creationDate: new Date(item.CreationDateTime)
                }));

            setRows([...userProjectData, ...additionalRows]);
        };

        processAndSetRows();
        console.log(rows)
    }, [storageData, storage_key]);

    useEffect(() => {
        document.title = getTabName(page_mode);
    }, [page_mode]);

    function getTabName(mode) {
        switch (mode) {
            case 'file_management':
                return 'Data Manager';
            case 'project_management':
                return 'Project Manager';
            case 'file_selection':
                return 'File Selection';
            case 'project_selection':
                return 'Project Selection';
            default:
                return 'MetaboStorage';
        }
    }

    useEffect(() => {
        if (searchTerm === '') {
            handleProjectRoot();
        }
    }, [searchTerm]);


    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.4,
            renderCell: (params) => {
                if (params.row.type === 'Demo Project') {
                    return <img src={demoProjectIcon} alt="Demo" style={{width: '30px', height: '30px'}}/>
                }
                return <img src={projectIcon} alt="Project" style={{width: '30px', height: '30px'}}/>
            },
        },
        {field: 'projectName', headerName: 'Project Name', flex: 1.5},
        {
            field: 'creationDate',
            headerName: 'Date of Creation',
            type: 'date',
            flex: 0.7,
            renderCell: (params) => {
                const utcDate = new Date(params.value + 'Z');

                return utcDate.toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false // Use 24-hour format
                });
            }
        },
        {
            field: 'action',
            headerName: '',
            flex: 0.2,
            renderCell: (params) => (
                <>
                    <IconButton
                        aria-label="more"
                        aria-controls="action-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent event from bubbling up to the DataGrid's onCellClick
                            handleActionMenuClick(e, params.row.id);
                        }}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id="action-menu"
                        anchorEl={actionMenuAnchor}
                        keepMounted
                        open={Boolean(actionMenuAnchor) && currentRowId === params.row.id}
                        onClose={handleActionMenuClose}
                    >
                        <MenuItem onClick={(e) => {
                            e.stopPropagation();
                            handleActionMenuClose();
                            handleProjectLoad([params.row]);
                        }}>Load Project</MenuItem>
                        <MenuItem onClick={(e) => {
                            e.stopPropagation();
                            handleActionMenuClose();
                            handlePipelineLoad([params.row]);
                        }}>Load Pipeline</MenuItem>
                        <MenuItem onClick={(e) => {
                            e.stopPropagation();
                            handleActionMenuClose();
                            // Find the project details
                            const projectDetails = rows.find(project => project.id === currentRowId);
                            setCurrentProjectDetails(projectDetails);
                            setDetailsDialogOpen(true);
                        }}>Details</MenuItem>
                        <Modal open={detailsDialogOpen}
                               onClose={() => setDetailsDialogOpen(false)}
                               onClick={(e) => e.stopPropagation()}
                        >
                            <ModalDialog sx={{width: '50%'}}>
                                <ModalClose/>
                                <Typography level="h4">Project Details</Typography>
                                {currentProjectDetails && (
                                    <>
                                        <Typography variant="body1"><strong>Project
                                            Name:</strong> {currentProjectDetails.projectName}</Typography>
                                        <Typography variant="body1"><strong>Description:</strong>
                                            <div>
                                                {currentProjectDetails.project_narration}
                                            </div>
                                        </Typography>
                                        <br></br>
                                        <Typography variant="body1">
                                            <strong>Collaborators:</strong>
                                            <div>
                                                {currentProjectDetails.collaborators.map((email, index) => (
                                                    <div key={index}>{email}</div>
                                                ))}
                                            </div>
                                        </Typography>
                                    </>
                                )}
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                    <Button onClick={() => setDetailsDialogOpen(false)} color="primary"
                                            variant="outlined"
                                    >
                                        Close
                                    </Button>
                                </Box>
                            </ModalDialog>
                        </Modal>
                        {params.row.type !== 'Demo Project' && (
                            <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                handleActionMenuClose();
                                // Find the project details
                                const projectDetails = rows.find(project => project.id === currentRowId);
                                setCurrentProjectDetails(projectDetails);
                                setEditDialogOpen(true);
                            }}>Edit/Share</MenuItem>
                        )}
                        <Modal open={editDialogOpen}
                               onClose={() => setEditDialogOpen(false)}
                               onClick={(e) => e.stopPropagation()}
                        >
                            <ModalDialog sx={{width: "50%"}}>
                                <ModalClose/>
                                <Typography level="h4">Edit Project Details</Typography>
                                {currentProjectDetails && (
                                    <>
                                        <TextField
                                            label="Project Name*"
                                            fullWidth
                                            multiline
                                            rows={1}
                                            margin="dense"
                                            defaultValue={currentProjectDetails.projectName}
                                            onChange={(e) => setCurrentProjectDetails({
                                                ...currentProjectDetails,
                                                projectName: e.target.value
                                            })}
                                        />
                                        <TextField
                                            label="Description"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            margin="dense"
                                            defaultValue={currentProjectDetails.project_narration}
                                            onChange={(e) => setCurrentProjectDetails({
                                                ...currentProjectDetails,
                                                project_narration: e.target.value
                                            })}
                                        />
                                        <TextField
                                            label="Collaborators (line-separated)"
                                            fullWidth
                                            margin="dense"
                                            multiline
                                            rows={4}
                                            placeholder="Please type the email addresses of the collaborators you want to share the project with. Enter each email on a new line."
                                            defaultValue={currentProjectDetails.collaborators.join('\n')}
                                            onChange={(e) => setCurrentProjectDetails({
                                                ...currentProjectDetails,
                                                collaborators: e.target.value.split('\n').map(item => item.trim())
                                            })}
                                        />
                                    </>
                                )}
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                    <Button onClick={() => setEditDialogOpen(false)} color="primary" variant="outlined">
                                        Cancel
                                    </Button>
                                    <Button onClick={() => {
                                        handleUpdateProject(currentProjectDetails)
                                    }} color="primary" variant="outlined" loading={isUpdatingProject}>
                                        Save
                                    </Button>
                                </Box>
                            </ModalDialog>
                        </Modal>
                        {params.row.type !== 'Demo Project' && (
                            <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                handleActionMenuClose();
                                const projectDetails = rows.find(project => project.id === currentRowId);
                                handleDeleteClick(projectDetails, currentRowId);
                            }}
                                      style={{color: 'red'}}
                            >
                                Delete
                            </MenuItem>
                        )}
                        <Modal open={isRunningJobDialogOpen}
                               onClose={() => {
                                   setIsRunningJobDialogOpen(false);
                                   setIsDeletingProject(false);
                               }}
                        >
                            <ModalDialog>
                                <ModalClose/>
                                <Typography level="h4">Running Jobs Detected</Typography>
                                <Typography variant="body1">
                                    There are still running jobs associated with this project. Deleting the project now may interrupt these jobs.
                                </Typography>
                                <Typography variant="body1">
                                    Are you sure you want to proceed with deleting the project?
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                    <Button onClick={() => {
                                        setIsRunningJobDialogOpen(false);
                                        setIsDeletingProject(false);
                                    }}
                                            color="primary"
                                            variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={() => {
                                        setIsRunningJobDialogOpen(false);
                                        setDeleteDialogOpen(true); // Proceed to delete confirmation modal
                                    }}
                                            color="danger"
                                            variant="outlined"
                                    >
                                        Proceed
                                    </Button>
                                </Box>
                            </ModalDialog>
                        </Modal>
                        <Modal onClick={(event) => event.stopPropagation()}
                               open={deleteDialogOpen}
                               onClose={() => {
                                   setDeleteDialogOpen(false);
                                   setDeleteConfirmationChecked(false);
                                   setIsDeletingProject(false);
                               }}
                        >
                            <ModalDialog>
                                <ModalClose/>
                                <Typography level="h4">Confirm Deletion</Typography>
                                {currentProjectDetails && (
                                    <>
                                        <Typography variant="body1">
                                            <strong>Project Name:</strong> {currentProjectDetails.projectName}
                                        </Typography>
                                        <Typography style={{color: 'red', fontWeight: 'bold'}}>
                                            Deleting the specified project can't be undone. To confirm, please check the box below.
                                        </Typography>
                                        <Checkbox variant="outlined"
                                                  checked={deleteConfirmationChecked}
                                                  onChange={(e) => setDeleteConfirmationChecked(e.target.checked)}
                                                  label={`Permanently delete project: ${currentProjectDetails.projectName}`}
                                        />
                                    </>
                                )}
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                    <Button onClick={() => {
                                        setDeleteDialogOpen(false);
                                        setDeleteConfirmationChecked(false);
                                        setIsDeletingProject(false);
                                    }}
                                            color="primary"
                                            variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={() => {
                                        handleDelete();
                                    }}
                                            color="danger"
                                            variant="outlined"
                                            disabled={!deleteConfirmationChecked}
                                            loading={isDeletingProject}
                                            loadingPosition="start"
                                    >
                                        Confirm Deletion
                                    </Button>
                                </Box>
                            </ModalDialog>
                        </Modal>
                    </Menu>
                </>
            ),
        },
    ];

    const fetchDemoProjectData = async (key) => {
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();

        // Return the promise from the axios call
        return axios.post(
            `/api/project/${key}/`,
            {
                parameters: {
                    folder_name: 'project',
                    encrypted_id: key,
                    version: versionType
                },
                request_name: 'enter_project',
                job_id: job_id
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            })
            .then(response => {
                if (response.data.status === "success") {
                    const data_info = response.data.response_data.storage_json;

                    const demoProjectData = data_info
                        .filter(item => item.ParentNode === current_fold_name)
                        .map((item, index) => ({
                            id: item.ProjectId,
                            type: key === '5MDSLse9kgHEewgu' ? 'Demo Project' : 'Project',
                            encrypted_id: item.id,
                            project_id: item.ProjectId,
                            projectName: item.FileName,
                            project_narration: item.ProjectNarration,
                            collaborators: item.collaboratorList,
                            creationDate: new Date(item.CreationDateTime)
                        }));

                    return demoProjectData;
                } else {
                    console.error("Error enter:\n", response.data.response_data.error_message);
                    return [];
                }
            })
            .catch(error => {
                console.error("Error fetching folder content:", error);
                return [];
            });
    };

    const handleDeleteClick = async (projectDetails, rowId) => {
        setIsPageLoading(true);
        if (!projectDetails) return;

        const job_id = uuidv4();

        try {
            // Step 1: Check if any jobs are running
            const checkResponse = await axios.post(
                '/api/check_running_job/',
                {
                    parameters: {
                        user_id: userId,
                        project_id: projectDetails.project_id,
                        version: versionType
                    },
                    job_id: job_id,
                    request_name: 'check_running_job'
                },
                {
                    headers: {'X-CSRFToken': csrfToken},
                    withCredentials: true
                }
            );
            setIsPageLoading(false);

            if (checkResponse.data.status === "success") {
                const isRunning = checkResponse.data.response_data;

                if (isRunning) {
                    // Open the "Running Jobs" dialog
                    setIsRunningJobDialogOpen(true);
                    // Store the project details and row ID for later use
                    setCurrentProjectDetails(projectDetails);
                    setCurrentRowId(rowId);
                } else {
                    // No running jobs, proceed to delete confirmation modal
                    setCurrentProjectDetails(projectDetails);
                    setCurrentRowId(rowId);
                    setDeleteDialogOpen(true);
                }
            } else {
                console.error("Error checking running jobs:\n", checkResponse.data.response_data.error_message);
                alert("Error checking running jobs. Please try again.");
            }
        } catch (error) {
            console.error("Error checking running jobs:", error);
            alert("Error checking running jobs. Please try again.");
        }
    };

    const handleDelete = async () => {
        const job_id = uuidv4();
        const projectToDelete = currentProjectDetails;

        if (!projectToDelete) {
            console.error("Project not found");
            return;
        }

        setIsDeletingProject(true);

        try {
            // Proceed with deletion
            const deleteResponse = await axios.post(
                '/api/delete/project/',
                {
                    parameters: {
                        user_id: userId,
                        storage_id: storage_key,
                        project_id: projectToDelete.project_id,
                        start_datetime: new Date(),
                        version: versionType
                    },
                    request_name: 'delete_project',
                    job_id: job_id
                },
                {
                    headers: {'X-CSRFToken': csrfToken},
                    withCredentials: true
                }
            );

            if (deleteResponse.data.status === "success") {
                // Update local state to remove the deleted project
                setDeleteConfirmationChecked(false);
                setIsDeletingProject(false);
                setDeleteDialogOpen(false);
                setRows(prevRows => prevRows.filter(project => project.id !== currentRowId));
                setSelectedRows([]);
            } else {
                console.error("Error deleting project:\n", deleteResponse.data.response_data.error_message);
                alert("Error deleting project. Please try again.");
                setIsDeletingProject(false);
            }
            handleProjectRoot();

        } catch (error) {
            console.error("Error deleting project:", error);
            alert("Error deleting project. Please try again.");
            setIsDeletingProject(false);
        }
    };


    const handleUpdateProject = (updatedProjectDetails) => {
        // ---validate updated project details start---
        const selectedProject = rows.find(project => project.id === currentRowId);

        if (updatedProjectDetails.projectName.trim() === '') {
            showErrorDialog("Project name is required.");
            return false;
        }

        const job_id = uuidv4();
        setIsUpdatingProject(true);
        // ---validate updated project details end---
        // ---send project update request---
        axios.post(
            '/api/edit_project/',
            {
                parameters: {
                    user_id: userId,
                    storage_id: storage_key,
                    project_id: updatedProjectDetails.project_id,
                    curr_project_name: selectedProject.projectName,
                    project_name: updatedProjectDetails.projectName,
                    curr_project_narration: selectedProject.project_narration,
                    project_narration: updatedProjectDetails.project_narration,
                    curr_collaborators: selectedProject.collaborators,
                    collaborators: updatedProjectDetails.collaborators,
                    start_datetime: new Date(),
                    version: versionType
                },
                request_name: 'edit_project',
                job_id: job_id
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        )
            // ---send project update request end---
            // ---handle project update response start---
            .then(response => {
                setIsUpdatingProject(false);
                if (response.data.status === "duplicated name") {
                    // If the server indicates a duplicated name, show the alert dialog
                    showErrorDialog("A project with this name already exists. Please choose a different name.");
                    return;
                }

                if (response.data.status === "success") {
                    // Update local state with new project details
                    setRows(prevRows => prevRows.map(row => {
                        if (row.id === updatedProjectDetails.id) {
                            return updatedProjectDetails;
                        }
                        return row;
                    }));
                    setEditDialogOpen(false);
                } else {
                    // Log the error if project update failed
                    console.error("Error updating project:\n", response.data.response_data.error_message);
                }
                handleProjectRoot();
            })
            .catch(error => {
                console.error("Error updating project:", error);
            });
        // ---handle project update response end---

        return true;
    };


    const handleNewProjectClick = () => {
        // Generate a unique ID using uuidv4 when "New Project" is clicked
        setProjectId(uuidv4());

        // Open the dialog
        setProjectDialogOpen(true);
    };


    const handleCreateProject = () => {
        // ---validate project data start---
        if (projectName.trim() === '') {
            showErrorDialog("Project name is required.");
            return;
        }

        const collaboratorList = projectCollaborator.trim().split('\n'); // Split emails by new line to get an array
        const job_id = uuidv4();
        setIsCreatingProject(true);

        // ---validate project data end---
        // ---send project creation request start---
        axios.post(
            '/api/create_project/',
            {
                parameters: {
                    user_id: userId,
                    project_id: projectId,
                    storage_id: storage_key,
                    projectName: projectName,       // Name of the new directory
                    project_narration: projectDescription,   // Path where the directory should be created
                    collaborators: collaboratorList,      // Key for the storage
                    start_datetime: new Date(),
                    version: versionType
                },
                request_name: 'create_project', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            })
            // ---send project creation request end---
            // ---handle project creation response start---
            .then(response => {
                if (response.data.status === "duplicated name") {
                    setIsCreatingProject(false);
                    // If the server indicates a duplicated name, show the alert dialog
                    showErrorDialog("A project with this name already exists. Please choose a different name.");
                    return;
                }

                if (response.data.status === "success") {
                    const newProject = {
                        id: projectId,  // the unique ID generated when "New Project" is clicked
                        type: 'Project',
                        projectName: projectName,
                        project_narration: projectDescription,  // Added description
                        collaborators: collaboratorList,  // Added collaborators array
                        creationDate: new Date(),
                        size: null
                    };

                    // Update the rows state with the new project
                    setRows(prevRows => [...prevRows, newProject]);

                    // Reset state variables
                    setIsCreatingProject(false);
                    setProjectDialogOpen(false);
                    setProjectName("");
                    setProjectDescription("");
                    setProjectCollaborator("");
                    setProjectId("");  // Reset the unique ID (or generate a new one if preferred)
                } else {
                    // Log the error if directory creation failed
                    console.error("Error creat folder:\n", response.data.response_data.error_message);
                }
                handleProjectRoot();
            })
            .catch(error => {
                console.error("Error creat folder:", error);
            });
        // ---handle project creation response end---
    };


    const handleActionMenuClick = (event, rowId) => {
        setCurrentRowId(rowId);
        setActionMenuAnchor(event.currentTarget);
        event.stopPropagation(); // This will prevent the event from reaching the row
    };


    const handleActionMenuClose = (event) => {
        setActionMenuAnchor(null);
        event && event.stopPropagation();
    };


    const navigate = useNavigate();

    const handleSearchClear = () => {
        setSearchTerm('');
        handleProjectRoot();
    };

    const handleSearchClick = () => {
        const filteredProjects = rows.filter(project => project.projectName.includes(searchTerm));
        setRows(filteredProjects);
    };

    const handleRowSelection = (row) => {
        setActionTaken(false);
        // If the row is already selected, remove it from the selection
        if (selectedRows.includes(row.id)) {
            setSelectedRows(selectedRows.filter(id => id !== row.id));
        } else {
            setSelectedRows([row.id]);
        }
    };

    const handleProjectLoad = (selectedRowIds) => {
        console.log(selectedRowIds);
        let DataList = [];
        selectedRowIds.forEach(rowId => {
            DataList.push(rowId);
        })
        const dataToSend = {
            dataType: 'project',
            result: DataList,
            sessionId: sessionId_back,
            callback_url: callback_url,
            storage_key: rows.find(project => project.id === selectedRowIds[0]).type === "Project" ? storage_key : "5MDSLse9kgHEewgu"
        };

        const originalOpener = window.myGlobalOpener;
        if (originalOpener && !originalOpener.closed) {
            originalOpener.postMessage(dataToSend, callback_url);
            window.close();
        } else {
            console.error('The opening window is no longer available.');
        }
    };

    const handlePipelineLoad = (selectedRowIds) => {
        console.log(selectedRowIds);
        let DataList = [];
        selectedRowIds.forEach(rowId => {
            DataList.push(rowId);
        })
        const dataToSend = {
            dataType: 'pipeline',
            result: DataList,
            sessionId: sessionId_back,
            callback_url: callback_url,
            storage_key: rows.find(project => project.id === selectedRowIds[0]).type === "Project" ? storage_key : "5MDSLse9kgHEewgu"
        };
        const originalOpener = window.myGlobalOpener;
        if (originalOpener && !originalOpener.closed) {
            originalOpener.postMessage(dataToSend, callback_url);
            window.close();
        } else {
            console.error('The opening window is no longer available.');
        }
    };

    const handleShareRoot = () => {
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        const encrypted_id = 0;
        axios.post(
            '/api/share/${storage_key}/',
            {
                parameters: {
                    folder_name: 'project',
                    encrypted_id: storage_key,
                    version: versionType
                },
                request_name: 'enter_share_with_me', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: storage_key + '/' + 'projects' + '/',
                    current_fold_name: 'projects',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                    version: versionType
                };

                // Navigate to project page with the unique URL
                navigate(`/share-with-me/${storage_key}`, {state: userData});// Navigate to CloudStorage with the unique URL
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }

    const handleStorageRoot = () => {

        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        axios.post(
            '/api/storage/${encrypted_id}/',
            {
                parameters: {
                    folder_name: 'useruploads',
                    encrypted_id: storage_key,
                    current_path: `${S3RootPath}/` + storage_key + '/',
                    version: versionType
                },
                request_name: 'enter_folder', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: `${S3RootPath}/` + storage_key + '/' + 'useruploads' + '/',
                    current_fold_name: 'useruploads',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                    version: versionType
                };

                // Navigate to CloudStorage with the unique URL
                navigate(`/storage/${storage_key}`, {state: userData});
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }


    const handleProjectRoot = () => {
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        const encrypted_id = 0;
        axios.post(
            '/api/project/${storage_key}/',
            {
                parameters: {
                    folder_name: 'project',
                    encrypted_id: storage_key,
                    version: versionType
                },
                request_name: 'enter_project', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: storage_key + '/' + 'projects' + '/',
                    current_fold_name: 'projects',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                    version: versionType
                };

                // Navigate to project page with the unique URL
                navigate(`/project-manager/${storage_key}`, {state: userData});// Navigate to CloudStorage with the unique URL
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }


    const handleProjectSelectionConfirm = (e) => {

    };

    const handleProjectSelectionCancel = (e) => {

    };

    const showErrorDialog = (message) => {
        setErrorMessage(message);
        setShowErrorSnackbar(true);
    };

    const closeErrorDialog = () => {
        setShowErrorSnackbar(false);
    };


    return (
        <div className="page-container">
            <Backdrop sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
                      open={isPageLoading}
            >
                <CircularProgress color="inherit"/>
                <Typography variant="h6"
                            sx={{
                                mt: 2, // Adds margin-top for spacing
                                color: '#fff',
                                fontWeight: 'bold',
                                textAlign: 'center', // Centers the text horizontally
                            }}
                >
                    Gathering information for your project
                </Typography>
            </Backdrop>

            <Snackbar
                variant="soft"
                color="warning"
                open={showErrorSnackbar}
                onClose={() => setShowErrorSnackbar(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                startDecorator={<WarningAmberOutlinedIcon/>}
                endDecorator={
                    <Button
                        onClick={() => setShowErrorSnackbar(false)}
                        size="sm"
                        variant="soft"
                        color="warning"
                    >
                        Dismiss
                    </Button>
                }
            >
                {errorMessage}
            </Snackbar>
            <h1>Welcome, {username}!</h1>
            <div className="flex-container">
                <div className="left-nav">
                    {pageMode !== "project selection" && (
                        <TextField
                            fullWidth
                            placeholder="Search the Project"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleSearchClick}>
                                            <Search/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchTerm && (
                                            <IconButton onClick={handleSearchClear}>
                                                <ClearOutlined style={{fontSize: '20px'}}/>
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    <Box sx={{mt: pageMode === "project_selection" ? 0 : 2,}}>
                        <ButtonGroup
                            fullWidth
                            variant="contained"
                            color="primary"
                            orientation="vertical"
                            sx={{
                                fontSize: "larger", // or a specific size like "20px"
                                '& .MuiButton-root': {
                                    padding: "12px 24px",  // Adjust padding as needed
                                }
                            }}
                        >
                            {(pageMode !== "project_selection" && pageMode !== "project_management") && (
                                <Button
                                    onClick={() => {
                                        setSelectedButton('my storage')
                                        handleStorageRoot();
                                    }}
                                    variant={selectedButton === 'my storage' ? 'solid' : 'outlined'}
                                >
                                    My Storage
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    setSelectedButton('my project');
                                    handleProjectRoot();
                                }}
                                variant={selectedButton === 'my project' ? 'solid' : 'outlined'}
                            >
                                My Projects
                            </Button>
                            <Button
                                onClick={() => {
                                    setSelectedButton('share with me');
                                    handleShareRoot()
                                }}
                                variant={selectedButton === 'share with me' ? 'solid' : 'outlined'}
                            >
                                Shared with Me
                            </Button>
                        </ButtonGroup>
                    </Box>
                </div>
                <div className="main-content">
                    {pageMode !== "project_selection" && (
                        <Box display="flex" flexDirection="row" justifyContent="flex-start" mb={2} gap={2}>
                            <Button
                                variant="outlined"
                                onClick={handleNewProjectClick}
                                className="custom-button"
                                startDecorator={<AddchartOutlinedIcon/>}
                            >
                                New Project
                            </Button>
                            <Modal open={projectDialogOpen}
                                   onClose={() => setProjectDialogOpen(false)}
                                   onClick={(e) => e.stopPropagation()}
                            >
                                <ModalDialog sx={{width: '50%'}}>
                                    <ModalClose/>
                                    <Typography level="h4">Create New Project</Typography>
                                    <Typography variant="body1">Project Name<span
                                        style={{color: 'red'}}>*</span>:</Typography>
                                    <TextField
                                        fullWidth
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Enter project name"
                                    />
                                    <Typography variant="body1">Description:</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={projectDescription}
                                        onChange={(e) => setProjectDescription(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Enter project description"
                                    />
                                    <Typography variant="body1">Collaborators to Share Project with:</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={projectCollaborator}
                                        onChange={(e) => setProjectCollaborator(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Please type the email addresses of the collaborators you want to share the project with. Enter each email on a new line."
                                    />
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                        <Button onClick={() => setProjectDialogOpen(false)} color="primary"
                                                variant="outlined">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleCreateProject} color="primary" variant="outlined" loading={isCreatingProject}
                                                loadingPosition="start">
                                            Create
                                        </Button>
                                    </Box>
                                </ModalDialog>
                            </Modal>
                            <Button variant="outlined"
                                    disabled={selectedRows.length === 0}
                                    onClick={() => handleProjectLoad(selectedRows)}
                                    startDecorator={<AssignmentTurnedInOutlinedIcon/>}
                            >
                                Load Project
                            </Button>
                            <Button variant="outlined"
                                    disabled={selectedRows.length === 0}
                                    onClick={() => handlePipelineLoad(selectedRows)}
                                    startDecorator={<AssignmentTurnedInOutlinedIcon/>}
                            >
                                Load Pipeline
                            </Button>
                        </Box>
                    )}
                    <div style={{height: 108 + (10 * 52.345) + 'px', width: '100%'}}>
                        <DataGrid
                            sx={{  // remove the outline on focus in datagrid
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                },  // remove the header selector
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    display: "none"
                                }
                            }}
                            checkboxSelection
                            rowSelectionModel={selectedRows}
                            onCellClick={handleRowSelection}
                            rows={rows}
                            columns={pageMode === "project_selection" ? columns.slice(0, -1) : columns}
                            sortModel={[
                                {
                                    field: 'creationDate',
                                    sort: 'desc', // or 'asc' for ascending order
                                },
                            ]}
                            autoHeight={false}
                        />
                    </div>
                    <br></br>
                    {
                        (pageMode === "development_mode" || pageMode === "project_selection") && (
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" mb={2} gap={2}>
                                <Button variant="outlined" onClick={handleProjectSelectionConfirm}
                                        className="custom-button">
                                    Confirm
                                </Button>
                                <Button variant="outlined" onClick={handleProjectSelectionCancel} className="custom-button">
                                    Cancel
                                </Button>
                            </Box>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ProjectManager;
